<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.keyword" clearable placeholder="抖音账号/昵称"/>
                </el-form-item>
                <el-form-item>
                    <el-select clearable v-model="options.params.status" placeholder="审核状态筛选">
                        <el-option :key="1" :value="1" label="待支付"></el-option>
                        <el-option :key="2" :value="2" label="待审核"></el-option>
                        <el-option :key="3" :value="3" label="已完成"></el-option>
                        <el-option :key="4" :value="4" label="已驳回"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
            <template slot="actions" slot-scope="scope">
                <template v-if="$perm('league_MemberProfileBackup_edit') && scope.row.status === 1">
                    <el-button type="text" @click="audit(scope.row)" icon="el-icon-check"
                               class="color-success"> 通过
                    </el-button>
                    <el-button type="text" @click="reject(scope.row)" icon="el-icon-close"
                               class="color-danger"> 驳回
                    </el-button>
                </template>
            </template>
        </crud>
        <tb-dialog ref="dialog" title="通过审核" @onOpened="$refs.level.getLists()" @onConfirm="doAudit">
            <el-form>
                <el-form-item label="评定等级" label-width="80px">
                    <tb-select ref="level" :options="levelOptions"
                               @change="val => this.auditForm.member_level_id = val"/>
                </el-form-item>
            </el-form>
        </tb-dialog>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: false,
                editBtn: false,
                delBtn: false,
                pager: true,
                addUrl: 'league.MemberProfileBackup.add',
                addPerm: 'league_MemberProfileBackup_add',
                editUrl: 'league.MemberProfileBackup.edit',
                editPerm: 'league_MemberProfileBackup_edit',
                delUrl: 'league.MemberProfileBackup.delete',
                delPerm: 'league_MemberProfileBackup_delete',
                listUrl: 'league.MemberProfileBackup.page',
                formLabelWidth: '100px',
                params: {
                    status: null,
                    keyword: null
                },
                columns: [
                    {
                        prop: "member_id", label: "提交用户", formatter: row => {
                            return row.member ? row.member.nickname : ''
                        }
                    },
                    {prop: "account", label: "抖音账号"},
                    {prop: "nickname", label: "抖音昵称", required: true},
                    {
                        prop: "avatar", label: "抖音头像", width: 72,
                        html: ({row}) => {
                            return row.avatar ? `<img class="list-user-avatar" src="${row.avatar}" alt="加载失败"/>` : null
                        }
                    },
                    {prop: "fans", label: "粉丝数量", type: "number"},
                    {
                        prop: "gender", label: "性别", formatter: row => {
                            return row.gender === 1 ? '男' : row.gender === 2 ? '女' : '未知'
                        }
                    },
                    {
                        prop: "level", label: "等级截图", width: 72,
                        html: ({row}) => {
                            return row.level ? `<a href="${row.level}" target="_blank"><img class="list-user-avatar" src="${row.level}" alt="加载失败"/></a>` : null
                        }
                    },
                    {prop: "region", label: "地区", required: true},
                    {
                        prop: "admin_id", label: "审核员", formatter: row => {
                            return row.admin ? row.admin.nickname : ''
                        }
                    },
                    {prop: "calc_time", label: "审核时间",},
                    {prop: "result", label: "审核结果",},
                    {
                        prop: "status", label: "审核状态", formatter: row => {
                            const status = ['待支付', '待审核', '已通过', '已驳回']
                            return status[row.status]
                        }
                    },
                    {prop: "create_time", label: "提审时间", width: 140},
                ],
            },
            levelOptions: {
                listUrl: 'league.MemberLevel.list',
                filter: (item, value) => {
                    return !value || item.name.includes(value)
                },
                label: "item.name",
                placeholder: '请选择评定会员等级'
            },
            auditForm: {
                member_level_id: null,
                id: null
            }
        }
    },
    methods: {
        audit: function (row) {
            this.auditForm.id = row.id
            // this.$refs.dialog.show()
            this.$helper._post(this, 'league.MemberProfileBackup.success', this.auditForm, resp => {
                this.$message.success(resp.message)
                this.$refs.crud.getLists()
                // this.$refs.dialog.hide()
            })
        },
        reject: function (row) {
            this.$prompt('请输入驳回原因', '驳回信息').then(({value}) => {
                if (!value || value.trim().length <= 0) return this.$message.warning('请输入驳回原因~')
                this.$helper._post(this, 'league.MemberProfileBackup.reject', {id: row.id, result: value}, resp => {
                    this.$message.success(resp.message)
                    this.$refs.crud.getLists()
                })
            }).catch(() => {
            });
        },
        doAudit: function () {
            if (!this.auditForm.member_level_id) return this.$message.warning("请选择评定等级~")
            this.$helper._post(this, 'league.MemberProfileBackup.success', this.auditForm, resp => {
                this.$message.success(resp.message)
                this.$refs.crud.getLists()
                this.$refs.dialog.hide()
            })
        }
    }
}
</script>

<style scoped>

</style>
